<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Merchandising from '@/services/Merchandising'
    import Swal from "sweetalert2";
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';


    export default {
        components: { Layout, PageHeader, VueBootstrapTypeahead},
        page: {
            title: "Promotions",

            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "Promotions",
                title_top: "Best Sellers",
                items: [
                    {
                        text: "Merchandising",
                    },
                    {
                        text: "Promotions",
                        active: true,
                        href: "/merchandising/promotions",
                    },
                ],
                 items_top: [
                    {
                        text: "Merchandising",
                    },
                    {
                        text: "Best Sellers",
                        active: true,
                        href: "/merchandising/promotions",
                    },
                ],
                isBusy: false,
                isBusyTop: false,
                error: null,
                errorTop: null,
                showModal:false,
                modalData:{},
                tableData: [],
                tableDataTop: [],
                totalRows: 0,
                totalRowsTop: 0,
                currentPage: 1,
                currentPageTop: 1,
                perPage: 10,
                perPageTop: 10,
                pageOptions: [10, 25, 50, 100],
                pageOptionsTop: [10, 25, 50, 100],
                filter: null,
                filterTop: null,
                filterOn: [],
                filterOnTop: [],
                sortBy: "PopustDo",
                sortByTop: "PopustDo",
                sortDesc: false,
                sortDescTop: false,
                fields: [
                    {
                        key: "Art_ID",
                        label: "ID",
                        sortable: true,
                    },
                    {
                        key: "Art_ImeKratko",
                        label: "Name",
                        sortable: false,
                    },
                    {
                        key: "Art_CenaP1",
                        label: "Price P1",
                        sortable: false,
                    },
                    {
                        key: "Art_CenaP3",
                        label: "Price P3",
                        sortable: true,
                    },
                    {
                        key: "PopustOd",
                        label: "Discount From",
                        sortable: true,
                    },
                    {
                        key: "PopustDo",
                        label: "Discount To",
                        sortable: true,
                    },
                    {
                        key: "promotion.promocena",
                        label: "Promotion Price",
                        sortable: true,
                    },

                    "action",
                ],
                fieldsTop: [
                    {
                        key: "Art_ID",
                        label: "ID",
                        sortable: true,
                    },
                    {
                        key: "Art_ImeKratko",
                        label: "Name",
                        sortable: false,
                    },
                    {
                        key: "Art_CenaP1",
                        label: "Price P1",
                        sortable: false,
                    },
                    {
                        key: "Art_CenaP3",
                        label: "Price P3",
                        sortable: true,
                    },
                    {
                        key: "PopustOd",
                        label: "Discount From",
                        sortable: true,
                    },
                    {
                        key: "PopustDo",
                        label: "Discount To",
                        sortable: true,
                    },
                    {
                        key: "promotion.promocena",
                        label: "Promotion Price",
                        sortable: true,
                    },

                    "action",
                ],
                csrf_token: localStorage.getItem('csrf_token'),
                searchPromo: '',
                suggestionsPromo:[],
                tryingToEditPromo: false,
                resetKeyPromo: 0,
                searchTopSale: '',
                suggestionsTopSale:[],
                tryingToEditTopSale: false,
                resetKeyTopSale: 0,



            };
        },
        async created() {
            await this.getPromotions()
            await this.getBestSeller()

            var params_promo = 'on_promotion=0';
            await this.getProductsAutocomplete(params_promo, 'promo');

            var params_top_sale = 'top_sale=0';
            this.getProductsAutocomplete(params_top_sale, 'top_sale');
        },
        methods: {
            async getPromotions(){
                try {
                    this.toggleBusy();
                    const response = await Merchandising.getPromotions()
                    this.tableData = response.data.data
                    this.totalRows = this.tableData.length
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                    this.totalRows = 0;
                }
            },

            async getBestSeller(){
                try {
                    this.toggleBusy();
                    const response = await Merchandising.getBestSeller()
                    this.tableDataTop = response.data.data
                    this.totalRowsTop = this.tableDataTop.length
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.errorTop = error.response.data.error ? error.response.data.error : "";
                    this.tableDataTop = []
                    this.totalRowsTop = 0;
                }
            },

             async getProductsAutocomplete(params, type){
                try {
                    this.toggleBusy();
                    const response = await Merchandising.getProductsAutocomplete(params)

                      var prep = [];
                      response.data.data.forEach(function (item) {
                            var product_name = item.id+' '+item.name
                            prep.push(product_name);
                      });

                      if(type == 'promo'){
                        this.suggestionsPromo = prep;
                      } else if(type == 'top_sale'){
                        this.suggestionsTopSale = prep;
                      }
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.errorTop = error.response.data.error ? error.response.data.error : "";

                }
            },


            removePromotion(id){
                Swal.fire({
                    title: "Remove promotion?",
                    text: "This promotion will be removed!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Remove!",
                }).then((result) => {
                    if (result.value) {
                        try {
                            Merchandising.removePromotion(id)
                                .then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if(res){
                                        this.getPromotions()
                                        Swal.fire("Remove promotion", "Promotion is successfully removed!", "success");
                                    }else{
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                })
                        }catch(error){
                            this.error = error.response.data.error ? error.response.data.error : "";
                        }
                    }
                });
            },

            removeBestSeller(id){
                Swal.fire({
                    title: "Remove best seller?",
                    text: "This best seller will be removed!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Remove!",
                }).then((result) => {
                    if (result.value) {
                        try {
                            Merchandising.removeBestSeller(id)
                                .then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if(res){
                                        this.getBestSeller()
                                        Swal.fire("Remove best seller", "Best seller is successfully removed!", "success");
                                    }else{
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                })
                        }catch(error){
                            this.error = error.response.data.error ? error.response.data.error : "";
                        }
                    }
                });
            },

            addPromotion(){
                this.tryingToEditPromo = true;
                var id = this.searchPromo.substring(0, this.searchPromo.indexOf(' '));
                this.resetKeyPromo += 1;
                this.searchPromo='';
                try {
                    Merchandising.addPromotion(id)
                        .then((response) => {
                            const res = response.data ? response.data : false;
                            const error = response.data.error ? response.data.error : 'Failed';
                            this.tryingToEditPromo = false;
                            if(res){
                                this.getPromotions()
                                Swal.fire("Add promotion", "Promotion is successfully added!", "success");
                                var params_promo = 'on_promotion=0';
                                this.getProductsAutocomplete(params_promo);
                            }else{
                                Swal.fire("Fail!", error, "warning");
                            }
                        })
                }catch(error){
                    this.error = error.response.data.error ? error.response.data.error : "";
                     this.tryingToEditPromo = false;
                }
            },

             addBestSeller(){
                this.tryingToEditTopSale = true;
                var id = this.searchTopSale.substring(0, this.searchTopSale.indexOf(' '));
                this.resetKeyTopSale += 1;
                this.searchTopSale='';
                try {
                    Merchandising.addBestSeller(id)
                        .then((response) => {
                            const res = response.data ? response.data : false;
                            const error = response.data.error ? response.data.error : 'Failed';
                            this.tryingToEditTopSale = false;
                            if(res){
                                this.getBestSeller()
                                Swal.fire("Add best seller", "Best seller is successfully added!", "success");
                                var params_top_sale = 'top_sale=0';
                                this.getProductsAutocomplete(params_top_sale);
                            }else{
                                Swal.fire("Fail!", error, "warning");
                            }
                        })
                }catch(error){
                    this.error = error.response.data.error ? error.response.data.error : "";
                     this.tryingToEditPromo = false;
                }
             },

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

             onFilteredTop(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRowsTop = filteredItems.length;
                this.currentPageTop = 1;
            },

            activeDate(dateFrom, dateTo){
                const currentDate = new Date();
                dateFrom = new Date(dateFrom);
                dateTo = new Date(dateTo);
                if(dateFrom < currentDate && dateTo > currentDate){
                    return true;
                }
                return false;

            }




        },


        middleware: "authentication",
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <form @submit.prevent="addPromotion" class="mb-5">
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <vue-bootstrap-typeahead :key="resetKeyPromo"
                                                  v-model="searchPromo"
                                                  :data="suggestionsPromo"

                                            />
                                        </div>
                                        <div class="col-sm-4">
                                            <input type="hidden" name="csrf_token" v-model="csrf_token" />
                                             <b-button variant="primary" @click="addPromotion" :disabled="tryingToEditPromo">
                                                <b-spinner v-show="tryingToEditPromo" small></b-spinner>Add Promotion
                                            </b-button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-sm-6">
                                <form @submit.prevent="addBestseller" class="mb-5">
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <vue-bootstrap-typeahead :key="resetKeyTopSale"
                                                  v-model="searchTopSale"
                                                  :data="suggestionsTopSale"

                                            />
                                        </div>
                                         <div class="col-sm-4">
                                            <input type="hidden" name="csrf_token" v-model="csrf_token" />
                                             <b-button variant="primary" @click="addBestSeller" :disabled="tryingToEditTopSale">
                                                <b-spinner v-show="tryingToEditTopSale" small></b-spinner>Add Best Seller
                                            </b-button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                         </div>

                        <h4 class="card-title">Total Promotions: {{totalRows}}</h4>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div  class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="promotions-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isBusy"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >


                                <template v-slot:cell(Art_CenaP1)="data">
                                   <div class="text-end">{{data.item.Art_CenaP1}}</div>
                                </template>

                                <template v-slot:cell(Art_CenaP3)="data">
                                   <div class="text-end" v-if="data.item.old_CenaP3">{{data.item.old_CenaP3}}</div>
                                   <div class="text-end" v-else>{{data.item.Art_CenaP3}}</div>
                                </template>

                                <template v-slot:cell(PopustOd)="data">
                                    <div v-if="activeDate(data.item.PopustOd, data.item.PopustDo)">
                                        <span class="badge bg-pill bg-soft-success font-size-12 text-capitalize">{{data.item.PopustOd}}</span>
                                    </div>
                                    <div v-else>
                                         <span class="badge bg-pill bg-soft-danger font-size-12 text-capitalize">{{data.item.PopustOd}}</span>
                                    </div>
                                </template>

                                <template v-slot:cell(PopustDo)="data">
                                    <div v-if="activeDate(data.item.PopustOd, data.item.PopustDo)">
                                        <span class="badge bg-pill bg-soft-success font-size-12 text-capitalize">{{data.item.PopustDo}}</span>
                                    </div>
                                    <div v-else>
                                         <span class="badge bg-pill bg-soft-danger font-size-12 text-capitalize">{{data.item.PopustDo}}</span>
                                    </div>
                                </template>

                                <template v-slot:cell(promotion.promocena)="data">
                                    <div v-if="activeDate(data.item.PopustOd, data.item.PopustDo)" class="text-end">
                                        <span class="badge bg-pill bg-soft-success font-size-12 text-capitalize ">{{data.item.promotion.promocena}}</span>
                                    </div>
                                    <div v-else>
                                         <span class="badge bg-pill bg-soft-danger font-size-12 text-capitalize"></span>
                                    </div>
                                </template>

                               <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove Promotion" @click="removePromotion(data.item.Art_ID)">
                                                <i class="uil uil-trash-alt font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


           <PageHeader :title="title_top" :items="items_top" />

            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Total Best Sellers: {{totalRowsTop}}</h4>
                            <div class="row mt-4">
                                <div class="col-sm-12 col-md-6">
                                    <div class="dataTables_length">
                                        <label class="d-inline-flex align-items-center">
                                            Show&nbsp;
                                            <b-form-select
                                                    v-model="perPageTop"
                                                    size="sm"
                                                    :options="pageOptionsTop"
                                            ></b-form-select
                                            >&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-6">
                                    <div
                                            id="top-table_filter"
                                            class="dataTables_filter text-md-end"
                                    >
                                        <label class="d-inline-flex align-items-center">
                                            Search:
                                            <b-form-input
                                                    v-model="filterTop"
                                                    type="search"
                                                    placeholder="Search..."
                                                    class="form-control form-control-sm ms-2"
                                            ></b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-responsive mb-0">
                                <b-table
                                        :busy="isBusyTop"
                                        :items="tableDataTop"
                                        :fields="fieldsTop"
                                        responsive="sm"
                                        :per-page="perPageTop"
                                        :current-page="currentPageTop"
                                        :sort-by.sync="sortByTop"
                                        :sort-desc.sync="sortDescTop"
                                        :filter="filterTop"
                                        :filter-included-fields="filterOnTop"
                                        show-empty
                                        empty-text="No Data Found"
                                        @filtered="onFilteredTop"
                                >

                                <template v-slot:cell(Art_CenaP1)="data">
                                   <div class="text-end">{{data.item.Art_CenaP1}}</div>
                                </template>

                                <template v-slot:cell(Art_CenaP3)="data">
                                   <div class="text-end" v-if="data.item.old_CenaP3">{{data.item.old_CenaP3}}</div>
                                   <div class="text-end" v-else>{{data.item.Art_CenaP3}}</div>
                                </template>

                                <template v-slot:cell(PopustOd)="data">
                                    <div v-if="activeDate(data.item.PopustOd, data.item.PopustDo)">
                                        <span class="badge bg-pill bg-soft-success font-size-12 text-capitalize">{{data.item.PopustOd}}</span>
                                    </div>
                                    <div v-else>
                                         <span class="badge bg-pill bg-soft-danger font-size-12 text-capitalize">{{data.item.PopustOd}}</span>
                                    </div>
                                </template>

                                <template v-slot:cell(PopustDo)="data">
                                    <div v-if="activeDate(data.item.PopustOd, data.item.PopustDo)">
                                        <span class="badge bg-pill bg-soft-success font-size-12 text-capitalize">{{data.item.PopustDo}}</span>
                                    </div>
                                    <div v-else>
                                         <span class="badge bg-pill bg-soft-danger font-size-12 text-capitalize">{{data.item.PopustDo}}</span>
                                    </div>
                                </template>

                                <template v-slot:cell(promotion.promocena)="data">
                                    <div v-if="activeDate(data.item.PopustOd, data.item.PopustDo)" class="text-end">
                                        <span class="badge bg-pill bg-soft-success font-size-12 text-capitalize ">{{data.item.promotion.promocena}}</span>
                                    </div>
                                    <div v-else>
                                         <span class="badge bg-pill bg-soft-danger font-size-12 text-capitalize"></span>
                                    </div>
                                </template>

                                 <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove Best Seller" @click="removeBestSeller(data.item.Art_ID)">
                                                <i class="uil uil-trash-alt font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>

                                    <template #table-busy>
                                        <div class="text-center text-danger my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading...</strong>
                                        </div>
                                    </template>

                                </b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="dataTables_paginate paging_simple_numbers float-end">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                            <b-pagination
                                                    v-model="currentPageTop"
                                                    :total-rows="totalRowsTop"
                                                    :per-page="perPageTop"
                                            ></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
</template>